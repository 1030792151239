<template>
    <div class="wrapper">
        <div
            v-if="credits.length > 0"
        >
            <div class="credits-container__table">
                <div class="credits-container__table-header">
                    <div class="credits-container__table-header__item credits-container__table-header__item-date">
                        Date
                    </div>
                    <div class="credits-container__table-header__item credits-container__table-header__item-title">
                        Title
                    </div>
                    <div class="credits-container__table-header__item credits-container__table-header__item-reference">
                        Reference ID
                    </div>
                    <div class="credits-container__table-header__item credits-container__table-header__item-debit">
                        Debit
                    </div>
                    <div class="credits-container__table-header__item credits-container__table-header__item-credit">
                        Credit
                    </div>
                    <div class="credits-container__table-header__item credits-container__table-header__item-balance">
                        Balance
                    </div>
                </div>

                <div class="credits-container__table-list">
                    <div
                        v-for="(credit,index) in credits"
                        :key="index"
                        class="credits-container__table-list__item"
                    >
                        <div class="credits-container__table-list__item-col credits-container__table-list__item-col__date">
                            <span v-if="credit.created_at">
                                {{ credit.created_at | moment_short_text }}
                            </span>
                            <span v-else>Invalid date</span>
                        </div>
                        <div class="credits-container__table-list__item-col credits-container__table-list__item-col__title">
                            &laquo;{{ credit.reference || '-' }}&raquo;
                        </div>

                        <div
                            class="credits-container__table-list__item-col credits-container__table-list__item-col__reference"
                            @click.prevent="redirectToReference(credit.orderid)"
                        >
                            {{ credit.orderid }}
                        </div>

                        <div class="credits-container__table-list__item-col credits-container__table-list__item-col__debit">
                            <span v-if="credit.debit > 0">{{ credit.debit | money }}</span>
                            <span v-else>-</span>
                        </div>

                        <div class="credits-container__table-list__item-col credits-container__table-list__item-col__credit">
                            <span v-if="credit.credit > 0">{{ credit.credit | money }}</span>
                            <span v-else>-</span>
                        </div>

                        <div class="credits-container__table-list__item-col credits-container__table-list__item-col__balance">
                            ${{ credit.balance }}
                        </div>
                    </div>
                </div>
                <custom-button
                    v-if="lastPage !== 1"
                    default
                    :loading="loading"
                    class="btn-base_colored sort-button btn-page-location"
                    @on-btn-click="loadMore"
                >
                    Load more
                </custom-button>
            </div>
        </div>

        <NoResult
            v-if="!loading && credits.length === 0"
            type="credits"
        />
        <radius-loader v-if="loading && credits.length === 0" />
    </div>
</template>

<script>
import NoResult from '@/components/common/NoResult'
import Api from '@/helpers/api/index.js'
import filtersMixin from '@/mixins/filters-mixin.js'
import RadiusLoader from '@/components/common/RadiusLoader'

export default {
    components: {
        NoResult,
        RadiusLoader
    },
    mixins: [
        filtersMixin
    ],
    metaInfo: {
        title: 'Credits'
    },
    data() {
        return {
            credits: [],
            rowsPerPage: 10,
            page: 1,
            lastPage: null,
            loading: true
        }
    },
    async created() {
        await this.getData()
    },
    methods: {
        async loadMore() {
            this.page += 1
            this.rowsPerPage = this.page * 10
            await this.getData()
        },
        async getData() {
            this.loading = true
            try {
                const { data } = await Api.get('/api/client-credit/list-credits', { count: this.rowsPerPage })
                this.credits = data.data
                this.lastPage = data.last_page
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.credits-container {
    width: 1200px;
    margin: 75px auto 0;
    &__table {

        &-header {
            background: inherit;
            display: flex;
            border-bottom: 1px solid $border-light;
            &__item {
                color: #afb2b9;
                font-size: 14px;
                font-weight: 400;
                padding: 0 0 20px;
                &-date {
                    flex-basis: 20%;
                    padding-left: 40px;
                }
                &-title {
                    flex-basis: 35%;
                }
                &-reference {
                    flex-basis: 15%;
                }
                &-debit {
                    flex-basis: 10%;
                    text-align: center;
                }
                &-credit {
                    flex-basis: 10%;
                    text-align: center;
                }
                &-balance {
                    flex-basis: 10%;
                    text-align: center;
                    padding-right: 40px;
                }
            }
        }
        &-list {
            display: flex;
            flex-direction: column;
            background-color: $white;
            &__item {
                border-bottom: 1px solid $border-light;
                // padding: 40px 0;
                padding: 30px 0;
                display: flex;
                &:last-child {
                    border-bottom: none;
                }
                &-col {
                    color: $font-color-main;
                    font-size: 14px;
                    font-weight: 400;
                    &__date {
                        flex-basis: 20%;
                        padding-left: 40px;
                    }
                    &__title {
                        flex-basis: 35%;
                        font-style: italic;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    &__reference {
                        flex-basis: 15%;
                        &:hover {
                            cursor: pointer;
                            color: $second-color;
                        }
                    }
                    &__debit {
                        flex-basis: 10%;
                        text-align: center;
                        // justify-content: right;
                        color: red;
                    }
                    &__credit {
                        flex-basis: 10%;
                        text-align: center;
                        /*padding-right: 40px;*/
                        // justify-content: right;
                        color: green;
                    }
                    &__balance {
                        flex-basis: 10%;
                        text-align: center;
                        // justify-content: right;
                        font-weight: bold;
                        padding-right: 40px;
                    }
                }
            }
        }
        .sort-button {
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }
    }
}
@media all and (max-width: 1200px) {
    .credits-container {
        width: calc(100% - 40px);
        margin: 85px 20px 0;
    }
}
@media all and (max-width: 996px) {
    .order-filter__label_box {
        display: none;
    }
    .credits-container__table-header {
        display: none;
    }
    .credits-container__table-list__item {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px;
        gap: 20px;
        &-col {
            font-size: 14px;
            font-weight: 400;
            position: relative;
            margin: 10px 0;
            flex-basis: 40%;
            padding-right: 0;
            &:nth-child(2) {
                order: -1;
                width: 100%;
                flex-basis: 100%;
            }
            &:nth-last-child(2), &:nth-last-child(3), &:last-child {
                flex-basis: 25%;
            }
            &::before {
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                left: 0;
                top: -20px
            }
            &__date {
                padding-left: 0;
                &::before {
                    content: 'Date';
                }
            }
            &__title {
                &::before {
                    content: 'Title';
                }
            }
            &__reference {
                &::before {
                    content: 'Reference ID';
                }
            }
            &__debit {
                text-align: left;
                &::before {
                    content: 'Debit';
                }
            }
            &__credit {
                text-align: left;
                &::before {
                    content: 'Credit';
                }
            }
            &__balance {
                text-align: left;
                &::before {
                    content: 'Balance';
                }
            }
        }
    }
}
@media all and (max-width: 576px) {
    .bc-credits {
        .wrapper {
            h1.page-title.breadcrumbs-item {
                align-items: flex-start;
            }
        }
    }
    .credits-container__table-list__item {
        padding: 10px;
    }
}
</style>
